import authDao from '../dao/authDao';
import webUtils from './webUtils';
import langCode from './langCode';
import {toast} from 'react-toastify';
import React from 'react';
import _get from 'lodash/get';
import contactUtils from './contactUtils';
import objectUtils from './objectUtils';
import {Trans} from 'react-i18next';
import Link from '../components/link';
import _isEmpty from 'lodash/isEmpty';
import cookieUtils from './cookieUtils';

const autoCompleteSignUpUtils = ({i18n, functionType, solutionType}) => {
  const langParam = webUtils.getLangPath(i18n.language);
  const isFreeTrialAllowed = async () => {
    try {
      const checkRes = await authDao.checkTrial({functionType, solutionType});
      if (checkRes.status === 'existed')
        return (window.location.href = webUtils.getSMBEnvReactAppUrl());
      else if (checkRes.status === 'expired')
        return (window.location.href = langParam + '/business/plan');
      else if (checkRes.status === 'form')
        return (window.location = `${langParam}${webUtils.goToFreeTrial(
          functionType,
          solutionType
        )}`);
      return 'ok';
    } catch (err) {
      return false;
    }
  };
  const isSubscriptionAllowed = async ({
    subscriptionType,
    recurringType,
    currencyType,
  }) => {
    try {
      const checkRes = await authDao.checkSubscription({
        functionType,
        solutionType,
      });
      if (checkRes.status === 'existed')
        window.location.href = webUtils.getSMBEnvReactAppUrl();
      else if (checkRes.status === 'form')
        return (window.location = `${langParam}${webUtils.goToSubscription({
          functionType,
          solutionType,
          subscriptionType,
          recurringType,
          currencyType,
        })}`);
      return 'ok';
    } catch (err) {
      toast.error(i18n.t('business:p.b.m.contactus.submit.failed', '.'));
      return false;
    }
  };
  const completeFreeTrial = async ({accountSummary}) => {
    const checkRes = await isFreeTrialAllowed({
      functionType,
      solutionType,
    });
    if (checkRes !== 'ok' || _isEmpty(accountSummary)) return false;
    try {
      if (checkRes === 'ok') {
        const creatTrial = await authDao.createTrial({
          account: {
            languageType: langCode.convertLanguageCode(i18n.language || 'en'),
            email: accountSummary.account.email,
            firstName: accountSummary.account.firstName,
            lastName: accountSummary.account.lastName,
            isNotificationEnabled: accountSummary.account.isNotificationEnabled,
          },
          solutionType,
          customer: {
            customerName: accountSummary.customer.customerName,
            phone: accountSummary.customer.phone,
            numberOfEmployees: accountSummary.customer.numberOfEmployees,
            country: accountSummary.customer.country,
            businessType: accountSummary.customer.businessType || 'MAKEUP',
            websiteUrl: accountSummary.customer.websiteUrl,
            functionType: functionType,
          },
          subscriptionType: 'PREMIUM_TRIAL',
          referralCode: '',
          refUrl: window.location.href,
          sourceUtm: {},
          responseType: 'json',
        });
        return true;
      }
    } catch (err) {
      errMsg(err);
      return false;
    }
  };
  const competeSubscription = async ({
    accountSummary,
    subscriptionType,
    currencyType,
    recurringType,
    numberOfLicenses,
    addonsQuantity,
    cp,
    pt,
  }) => {
    const checkRes = await isSubscriptionAllowed({
      functionType,
      solutionType,
    });
    if (checkRes !== 'ok' || _isEmpty(accountSummary)) return false;
    try {
      if (checkRes === 'ok') {
        const v = {
          account: {
            languageType: langCode.convertLanguageCode(i18n.language || 'en'),
            email: accountSummary.account.email,
            password: accountSummary.account.password,
            firstName: accountSummary.account.firstName,
            lastName: accountSummary.account.lastName,
            isNotificationEnabled: accountSummary.account.isNotificationEnabled,
          },
          solutionType,
          customer: {
            customerName: accountSummary.customer.customerName,
            phone: accountSummary.customer.phone,
            numberOfEmployees: accountSummary.customer.numberOfEmployees,
            country: accountSummary.customer.country,
            businessType: accountSummary.customer.businessType || 'MAKEUP',
            websiteUrl: accountSummary.customer.websiteUrl,
            functionType: functionType,
          },
          agreeTerms: true,
          subscriptionType: subscriptionType,
          recurringType: recurringType || 'ANNUAL',
          language: i18n.language,
          currencyType: currencyType || 'USD',
          referralCode: cookieUtils.getCookie('ref_code'),
          affiliationCode: cookieUtils.getCookie('affiliationCode'),
          pt,
          cp,
        };
        if (functionType === 'SKINCARE_PRO') {
          v.numberOfLicenses = numberOfLicenses;
          v.addonsQuantity = addonsQuantity;
          v.addonsMaxQuantity = 20 - numberOfLicenses;
        } else {
          v.addonsMaxQuantity = 6;
        }
        const creatSubscription = await authDao.createSubscription(v);
      }
    } catch (err) {
      errMsg(err);
      return false;
    }
  };
  const getSignUpInput = accountSummary => {
    const flattenObj = objectUtils.flattenObject(accountSummary);
    return [
      {value: _get(flattenObj, 'email'), name: 'email'},
      {
        value: _get(flattenObj, 'customerName'),
        name: 'businessName',
      },
      {value: _get(flattenObj, 'phone'), name: 'phone'},
      {
        value: _get(flattenObj, 'firstName'),
        name: 'firstName',
      },
      {
        value: _get(flattenObj, 'lastName'),
        name: 'lastName',
      },
      {
        value: contactUtils.convertEmployee(
          _get(flattenObj, 'numberOfEmployees')
        ),
        name: 'numberOfEmployees',
      },
      {value: _get(flattenObj, 'country'), name: 'country'},
      {
        value: _get(flattenObj, 'websiteUrl'),
        name: 'websiteUrl',
      },
      {value: 'Free Trial', name: 'Subsource'},
      {value: 'Opt-in from Website', name: 'Source'},
      {
        value: webUtils.getTagsPfByFunctionType(),
        name: 'Tags_PF',
      },
      {
        value: _get(flattenObj, 'isNotificationEnabled') ? '0' : '1',
        name: 'optout',
      },
    ];
  };
  const errMsg = err => {
    if (err.status === 426)
      toast.error(
        i18n.t(
          'business:p.b.u.signup.error.account.existed.or.expired',
          "Account creation unsuccessful because it looks like you're logged in. If you need to create a different account, you'll need to log out of your account before filling out the registration form."
        )
      );
    else
      toast.error(
        <Trans i18nKey="business:p.b.u.signup.error.create.account.fail">
          We're not able to create an account for you right now. Please try
          again in a few minutes or{' '}
          <Link to="/business/contact-us/support">contact us</Link> for help
          resolving this problem.
        </Trans>
      );
  };
  return {
    completeFreeTrial,
    getSignUpInput,
    isFreeTrialAllowed,
    errMsg,
    isSubscriptionAllowed,
    competeSubscription,
  };
};

export default autoCompleteSignUpUtils;
