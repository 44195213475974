import React from 'react';
import AsyncPage from './async';
import {Route} from 'react-router-dom';

export default [
  <Route
    key="/business/campaign/skincare-pro-aesthetic-simulation"
    exact
    path="/business/campaign/skincare-pro-aesthetic-simulation"
    component={AsyncPage}
  />,
];
