import React from 'react';
import AsyncPage from './async';
import ShowocaseLayout from '../../../../components/layout/ShowocaseLayout';
import {Redirect} from 'react-router';

export default [
  <ShowocaseLayout
    key="/business/showcase/face-analyzer"
    exact
    path="/business/showcase/face-analyzer"
    component={AsyncPage}
  />,
  <Redirect
    key="/business/showcase/face-analyzer/:type(attributes)?"
    exact
    from="/business/showcase/face-analyzer/:type(attributes)?"
    to="/business/showcase/face-analyzer?type=attributes"
  />,
  <Redirect
    key="/business/showcase/face-analyzer/:type(facial-ratio)?"
    exact
    from="/business/showcase/face-analyzer/:type(facial-ratio)?"
    to="/business/showcase/face-analyzer?type=facial-ratio"
  />,
];
