import React from 'react';
import AsyncPage from './async';
import BlankLayout from '../../../../components/layout/BlankLayout';
import ConfirmPageAsync from './component/confirmpage/async';

export default [
  <BlankLayout
    key="/business/user/free-trial"
    exact
    path="/business/user/free-trial"
    component={AsyncPage}
    showContactSales={false}
    demostorebutton={false}
  />,
  <BlankLayout
    key="/business/user/sign-up/:subscriptionType(premium|essential)?"
    exact
    path="/business/user/sign-up/:subscriptionType(premium|essential)?"
    component={AsyncPage}
    showContactSales={false}
    demostorebutton={false}
  />,
  <BlankLayout
    key="/business/user/signup-confirmed"
    exact
    path="/business/user/signup-confirmed"
    component={ConfirmPageAsync}
    showContactSales={false}
    demostorebutton={false}
  />,
];
