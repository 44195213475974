import React from 'react';
import AsyncComponent from './async';
import {Route} from 'react-router-dom';

export default [
  <Route
    key="/business/campaign/vto"
    exact
    path="/business/campaign/vto"
    component={AsyncComponent}
    breadcrumb={[
      {
        position: 1,
        name: 'Home',
        item: '/',
      },
      {
        position: 2,
        name: 'Business',
        item: '/business',
      },
      {
        position: 3,
        name: 'SMB Campaign',
        item: '/business/campaign/vto',
      },
    ]}
  />,
  <Route
    key="/business/campaign/vto-v1"
    exact
    path="/business/campaign/vto-v1"
    component={AsyncComponent}
    breadcrumb={[
      {
        position: 1,
        name: 'Home',
        item: '/',
      },
      {
        position: 2,
        name: 'Business',
        item: '/business',
      },
      {
        position: 3,
        name: 'SMB Campaign',
        item: '/business/campaign/vto-v1',
      },
    ]}
  />,
];
