const objectUtils = {
  flattenObject: (obj, result = {}) => {
    for (const [key, value] of Object.entries(obj)) {
      if (
        typeof value === 'object' &&
        value !== null &&
        !Array.isArray(value)
      ) {
        objectUtils.flattenObject(value, result);
      } else {
        result[key] = value;
      }
    }
    return result;
  },
};

export default objectUtils;
