import home from './home/routes';
import misc from './misc/routes';
import plan from './plan/routes';
import technologies from './technologies/routes';
import solutions from './solutions/routes';
import products from './products/routes';
import services from './services/routes';
import user from './user/routes';
import news from './news/routes';
import showcase from './showcase/routes';
import colortrends from './colortrends/routes';
import story from './story/routes';
import video from './video/routes';
import blog from './blog/routes';
import beautytechforumvirtual from './beautytechforumvirtual/routes';
import campaign from './campaign/routes';
import educationseries from './educationseries/routes';
import event from './event/routes';
import globalbeautytechforum from './globalbeautytechforum/routes';
import partners from './partners/routes';
import gcse from './gcse/routes';
import compliance from './compliance/routes';
import aiapi from './perfectapi/routes';

export default [
  home,
  misc,
  plan,
  solutions,
  services,
  user,
  products,
  news,
  showcase,
  colortrends,
  story,
  video,
  blog,
  beautytechforumvirtual,
  educationseries,
  campaign,
  technologies,
  event,
  globalbeautytechforum,
  partners,
  gcse,
  compliance,
  aiapi,
];
