import React, {useEffect, useState} from 'react';
import {notifyAPPWithCommand} from '../freetrial/component/step/notifyAPPWithCommand';
import authDao from '../../../../dao/authDao';
import recaptchaUtils from '../../../../utils/recaptchaUtils';
import _get from 'lodash/get';
import webUtils from '../../../../utils/webUtils';
import _includes from 'lodash/includes';

export default function AppSignIn() {
  const [error, setError] = useState('');
  const params = new URLSearchParams(window.location.search);
  const next = params.get('next');
  window.signIn = data => {
    const token = data && JSON.parse(data) && _get(JSON.parse(data), 'token');
    if (!token) {
      setError('no token');
      return;
    }
    recaptchaUtils.getToken().then(res => {
      authDao
        .signInWithToken(token, 'SKINCARE_PRO', res, next)
        .then(r => {
          window.location.href = _includes(r['redirectUrl'], '?')
            ? `${r['redirectUrl']}&signInWithApp=true`
            : `${r['redirectUrl']}?signInWithApp=true`;
        })
        .catch(err => {
          setError(err);
          console.log(err);
        });
    });
  };

  useEffect(() => {
    if (webUtils.isInYmkApp()) {
      notifyAPPWithCommand('signIn');
    }
  }, []);

  return (
    <div
      style={{
        paddingTop: '100px',
        fontSize: '30px',
        color: process.env.NODE_ENV !== 'production' ? '#212529' : '#fff',
      }}
    >
      In App SignIn Page
      {error && <div>Error Msg: {error}</div>}
    </div>
  );
}
