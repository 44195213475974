import smb from './smb/routes';
import skinDiagnostics from './skindiagnostic/routes';
import skinAnalysis from './skinanalysis/routes';
import vto from './vto/routes';
import virtualMakeup from './virtualmakeup/routes';
import _3dvtoformat from './3dvtoformat/routes';
import perfecttone from './perfecttone/routes';
import onlineserviceaestheticsimulation from './onlineserviceaestheticsimulation/routes';
import onlineserviceskinanalysis from './onlineserviceskinanalysis/routes';

export default [
  smb,
  skinDiagnostics,
  skinAnalysis,
  vto,
  virtualMakeup,
  _3dvtoformat,
  perfecttone,
  onlineserviceaestheticsimulation,
  onlineserviceskinanalysis,
];
